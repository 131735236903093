<template>
  <div>
    <!-- <master-render :rows="rows" v-model="model"></master-render> -->
    <v-row>
      <v-col cols="6">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>ทนาย</v-list-item-title>
            <v-list-item-subtitle>{{items[0].lawyer.text}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <v-col cols="6">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>ประเภท</v-list-item-title>
            <v-list-item-subtitle>{{items[0].type.text}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="6">

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>OA</v-list-item-title>
            <v-list-item-subtitle>{{items[0].oa.text}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="6">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>ส่วนเบิก</v-list-item-title>
            <v-list-item-subtitle>{{items[0].installmentPayment.text}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="6">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>จำนวนรายการ</v-list-item-title>
            <v-list-item-subtitle>{{model.caseCount||currency(0)}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>

      <v-col cols="6">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>จำนวนเงิน</v-list-item-title>
            <v-list-item-subtitle>{{model.total|currency(2)}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>

    </v-row>

  </div>
</template>

<script>
import { InputType, StatusCase, UserRole } from "./../js/constants";

import { createDynamicModel, sumList } from "./../js/custom";

import { fakeAbbr, getFakeUrl } from "./../_helpers/fake-url";

export default {
  props: {
    items: Array,
  },

  data() {
    return {
      model: {
        caseCount: 0,
        total: 0,
      },
    };
  },
  watch: {
    items: {
      handler(newVal) {
        this.model = {};
        createDynamicModel(this, this.model, this.rows);

        this.model.caseCount = this.items.length;
        this.model.total = sumList(this.items, "amount");
      },
      immediate: true,
    },
  },
};
</script>