<template>
  <div>
    <master-component
      class="search-case"
      ref="masterComponent"
      :config="config"
      :showAdd="false"
      :showEdit="false"
    >
      <template v-slot:table-isCompleteNCB="{ item }">
        <v-icon v-if="item.isCompleteNCB">mdi-checkbox-marked-circle</v-icon>
        <span style="visibility:hidden">.<span style="display:none">for workaround </span></span>
      </template>

      <template v-slot:table-baiberkRef="{ item }">
        <router-link
          :to="`/baiberk-details/${item.baiberkDeductId}`"
          target="_blank"
          style="text-decoration: none;"
        >{{ item.baiberkRef }}</router-link>
      </template>

      <template v-slot:table-custom-event-button="{ item }">
        <v-icon
          color="info"
          @click="seeDetails(item)"
        >launch</v-icon>
      </template>
    </master-component>

    <custom-dialog
      ref="summaryWithdrawDialog"
      title="สร้างใบเบิก"
      :showSubmitButton="false"
      :width="650"
    >
      <v-col class="white">
        <SummaryWithdrawForm :items="selected"></SummaryWithdrawForm>
      </v-col>

      <template v-slot:action>
        <div class="text-right"></div>
        <div class="flex-grow-1"></div>
        <v-btn
          v-if="baiberkId"
          color="info"
          @click="gotoBaiberk"
          text
        >ไปยังใบเบิก <v-icon style="top:2px">
            mdi-arrow-right-bold
          </v-icon></v-btn>

        <v-btn
          v-if="!baiberkId"
          color="info"
          @click="createBaiBerk"
        >สร้างใบเบิกเงิน</v-btn>
      </template>
    </custom-dialog>
  </div>
</template>

<script>
import {
  InputType,
  StatusCase,
  UserRole,
  StatusPayment,
  PaymentType,
  RevertType,
  InstallmentPayment,
} from "./../js/constants";
import CustomDialog from "./../components/custom/custom-dialog";

import { createDynamicModel, openNewTabWithDelay } from "./../js/custom";

import { fakeAbbr, getFakeUrl } from "./../_helpers/fake-url";
import SummaryWithdrawForm from "./summary-withdraw-form";
import UseFakeApiMixins from "./../_mixins/use-fake-api-mixins";
import { momentToThaiFormat } from "./../js/date-helper";
import moment from "moment";

import {
  getPaymentType,
  getStatusPayment,
  getLawyers,
  getBranches,
  getInstallmentPayments,
} from "../js/dropdown-service";
import { getToken } from "../js/authorization";

export default {
  mixins: [UseFakeApiMixins],
  components: {
    "custom-dialog": CustomDialog,
    SummaryWithdrawForm,
  },
  data() {
    return {
      config: {},
      selected: [],
      baiberkId: null,
    };
  },
  created() {
    this.initialConfig();
  },
  methods: {
    initialConfig() {
      this.config = {
        name: "request withdraw",
        shortName: "Request Withdraw",
        search: this.searchSection,
        form: {},
        url: this.urlSection,
        table: this.tableSection,
        permission: {},
      };
    },
    seeDetails(item) {
      let routeData = this.$router.resolve({
        name: "case-details",
        params: {
          id: item.caseId,
          currentTab: "3",
        },
      });
      window.open(routeData.href, "_blank");
    },
    showSummaryWithdrawDialog() {
      this.baiberkId = null;
      this.$refs.summaryWithdrawDialog.hideLoading();
      this.$refs.summaryWithdrawDialog.showDialog();
    },
    approve() {
      let count = this.selected.length;
      if (confirm(`ยืนยันอนุมัติคำร้องของเบิกเงิน ${count} รายการ`)) {
        // let url = "/withdraw/bulkApproveRequest";
        let url = "/withdraw/bulkApproveRequest";

        let payload = this.selected.map((x) => x.id);
        this.$http.post(url, payload).then((res) => {
          this.$refs.masterComponent.search();
          this.showAlertSuccess();
        });
      }
    },
    // cancelApprove() {
    //   let count = this.selected.length;

    //   if (count == 1) {
    //     if (
    //       confirm(`คุณต้องการยกเลิกการอนุมัติ ${count} รายการนี้ใช่หรือไม่ ?`)
    //     ) {
    //       let payloadTemp = {};
    //       payloadTemp.data = this.selected.map((x) => x.caseId);

    //       var _caseId = payloadTemp.data[0];

    //       let url = `/case/${_caseId}/Advance/UnApprove`;

    //       let payload = {};
    //       payload.data = this.selected.map((x) => x.id);
    //       payload.type = RevertType["ยกเลิกอนุมัติ"].id;

    //       console.log(this.selected);
    //       console.log(payload.data);

    //       this.$http.post(url, payload).then((res) => {
    //         this.$refs.masterComponent.search();
    //         this.showAlertSuccess();
    //       });
    //     }
    //   }
    // },

    cancelApprove() {
      let count = this.selected.length;

      if (
        confirm(`คุณต้องการยกเลิกการอนุมัติ ${count} รายการนี้ใช่หรือไม่ ?`)
      ) {
        let url = `/withdraw/UnApprove`;
        let ids = this.selected.map((x) => x.id);

        this.$http.post(url, ids).then((res) => {
          this.$refs.masterComponent.search();
          this.showAlertSuccess();
        });
      }
    },
    createBaiBerk() {
      let url = "/baiberk/create";

      let payload = {};
      payload.ids = this.selected.map((x) => x.id);

      this.$http.post(url, payload).then((res) => {
        this.$refs.masterComponent.search();
        this.showAlertSuccess();
        // this.$refs.summaryWithdrawDialog.hideDialog();
        this.baiberkId = res.id;

        // let routeData = this.$router.resolve({
        //   name: "baiberk-details",
        //   params: {
        //     id: res.id,
        //   },
        // });

        // openNewTabWithDelay({ url: routeData.href, delay: 3000 });
      });
    },
    gotoBaiberk() {
      let routeData = this.$router.resolve({
        name: "baiberk-details",
        params: {
          id: this.baiberkId,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
  computed: {
    bulkAction() {
      return [
        {
          text: "อนุมัติ (Advance เท่านั้น)",
          permission: [
            UserRole.ADMIN,
            UserRole.MANAGEMENT,
            UserRole.BACK_OFFICE,
          ],
          condition: ({ selected }) => {
            let result = selected.every(
              (x) =>
                x.status.id === StatusPayment["รออนุมัติ"].id &&
                x.type.id === PaymentType["advance"].id
            );

            return result;
          },
          func: () => {
            this.selected = this.$refs.masterComponent.getSelectedItems();

            if (this.selected.length > 0) {
              this.approve();
            } else {
              this.showAlertError("กรุณาเลือกรายการที่ต้องการ");
            }
          },
        },
        {
          text: "ยกเลิกอนุมัติ",
          permission: [
            UserRole.ADMIN,
            UserRole.MANAGEMENT,
            UserRole.BACK_OFFICE,
          ],
          condition: ({ selected }) => {
            let result = selected.every(
              (x) => x.status.id === StatusPayment["อนุมัติแล้ว"].id
            );

            return result;
          },
          func: () => {
            this.selected = this.$refs.masterComponent.getSelectedItems();

            if (this.selected.length > 0) {
              this.cancelApprove();
            } else {
              this.showAlertError("กรุณาเลือกรายการที่ต้องการ");
            }
          },
        },
        {
          text: "สร้างใบเบิกเงิน",
          permission: [
            UserRole.ADMIN,
            UserRole.MANAGEMENT,
            UserRole.BACK_OFFICE,
          ],
          condition: ({ selected }) => {
            let condition1 = selected.every(
              (x) => x.status.id === StatusPayment["อนุมัติแล้ว"].id
            );

            let condition2 =
              _.toArray(_.groupBy(selected, "lawyer.id")).length == 1;

            let condition3 =
              _.toArray(_.groupBy(selected, "oa.id")).length == 1;

            let condition4 =
              _.toArray(_.groupBy(selected, "type.id")).length == 1;

            let condition5 =
              _.toArray(_.groupBy(selected, "installmentPayment.id")).length ==
              1;

            let result =
              condition1 &&
              condition2 &&
              condition3 &&
              condition4 &&
              condition5;

            return result;
          },
          func: () => {
            this.selected = this.$refs.masterComponent.getSelectedItems();

            if (this.selected.length > 0) {
              this.showSummaryWithdrawDialog();
            } else {
              this.showAlertError("กรุณาเลือกรายการที่ต้องการ");
            }
          },
        },
      ];
    },
    titlesummaryWithdrawDialog() {
      return `เบิกเงิน ${this.selected.length} รายการ`;
    },
    urlSection() {
      let searchUrl = "/withdraw/request/search";
      // if (this.useFakeApi) {
      //   searchUrl =
      //      getFakeUrl(fakeAbbr.paymentRequestList.getRequestList) + searchUrl;
      // }

      return {
        search: searchUrl,
      };
    },
    searchSection() {
      return {
        searchOnInit: false,
        rows: [
          [
            {
              name: "lawyer",
              text: "ทนาย",
              type: this.isLawyer ? InputType.offset : InputType.dropdown,
              data: {
                promise: getLawyers(),
              },
            },
            {
              name: "type",
              text: "ประเภท",
              type: InputType.dropdown,
              data: {
                promise: getPaymentType(),
              },
            },
            {
              name: "oa",
              text: "OA",
              type: InputType.dropdown,
              data: {
                promise: getBranches({ authorize: true }),
              },
            },
            {
              name: "installmentPayment",
              text: "ส่วน",
              type: InputType.dropdown,
              data: {
                promise: getInstallmentPayments(),
              },
            },
          ],
          [
            {
              name: "status",
              text: "สถานะคำร้อง",
              type: InputType.dropdown,
              data: {
                promise: getStatusPayment().then((res) => {
                  let excludeStatus = [StatusPayment["ยังไม่ได้ทำรายการ"].id];

                  res.items = res.items.filter(
                    (x) => !excludeStatus.includes(x.id)
                  );
                  return res;
                }),
              },
            },
            {
              name: "lotNumber",
              text: "Lot Number",
              type: InputType.text,
            },
            {
              name: "blackNumber",
              text: "หมายเลขคดีดำ",
              type: InputType.text,
            },
            {
              name: "redNumber",
              text: "หมายเลขคดีแดง",
              type: InputType.text,
            },
          ],
          [
            {
              name: "date",
              text: " ช่วงวันที่ขอเบิก",
              type: InputType.begin_end_date,
              default: {
                begin: momentToThaiFormat({
                  moment: moment().subtract(1, "months"),
                }),
                end: momentToThaiFormat({ moment: moment() }),
              },
              columnClass: "col-sm-12 col-md-6",
            },
            {
              name: "advanceDeductStatus",
              text: "สถานะ Advance",
              type: InputType.dropdown,
              data: {
                promise: Promise.resolve({
                  items: [
                    { id: null, text: "ทั้งหมด" },
                    { id: true, text: "หักแล้ว" },
                    { id: false, text: "ยังไม่หัก" },
                  ],
                }),
              },
              showIf: (context) => {
                const model = context.model;

                let condition1 =
                  model.type && model.type.id == PaymentType.advance.id;

                if (condition1) {
                  return true;
                }

                return false;
              },
            },
            {
              name: "advanceExceed60Days",
              text: "Advance เกิน 60 วัน",
              type: InputType.checkBox,
              columnClass: "mt-2",
              showIf: (context) => {
                const model = context.model;

                let condition1 =
                  model.type && model.type.id == PaymentType.advance.id;
                let condition2 =
                  model.installmentPayment &&
                  model.installmentPayment.id ==
                    InstallmentPayment.FirstPayment;
                let condition3 =
                  model.advanceDeductStatus &&
                  model.advanceDeductStatus.id == true;

                if (condition1 && !condition2 && !condition3) {
                  return true;
                }

                return false;
              },
            },
          ],
        ],
      };
    },

    tableSection() {
      return {
        bulkAction: this.bulkAction,
        columns: [
          {
            name: "type.text",
            text: "ประเภท",
          },
          {
            name: "description",
            text: "รายละเอียด",
            style: {
              width: "210px",
            },
          },
          {
            name: "lawyer.text",
            text: "ทนาย",
          },
          {
            name: "installmentPayment.text",
            text: "ส่วน",
            style: {
              width: "58px",
            },
          },
          {
            name: "oa.text",
            text: "OA",
          },
          {
            name: "court.text",
            text: "ศาล",
          },
          {
            name: "deptorName",
            text: "ลูกหนี้",
            style: {
              width: "190px",
            },
          },
          {
            name: "blackNumber",
            text: "คดีดำ",
            style: {
              width: "10px",
            },
          },
          {
            name: "amount",
            text: "จำนวนเงิน",
            numeric: 2,
            class: "text-right",
          },
          {
            name: "status.text",
            text: "สถานะ",
          },
          {
            name: "isCompleteNCB",
            text: "NCB",
            class: "text-center",
          },
          {
            name: "baiberkRef",
            text: "ใบเบิกที่หัก",
          },
        ],
      };
    },
  },
};
</script>


<style>
.search-case .master-table table th,
.search-case .master-table table td {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.search-case .master-table table td.col-action {
  text-align: center;
}
</style>